import Image from "next/image";
import Link from "next/link";
import { FiMenu } from "react-icons/fi";

import Menu from "./Menu";

export function Top({ infos, menu, setMenu }) {
  const handleClick = () => {
    setMenu(!menu);
  };

  return (
    <div className="navbar sticky top-0 z-50 bg-white shadow-sm">
      <div className="container mx-auto flex px-4">
        <Link href="/">
          <div className="py-3 px-6 absolute flex bg-blue rounded-bl-full rounded-r-full w-40 lg:w-52">
            <Image width="180" height="90" src="/logo.svg" alt="" />
          </div>
        </Link>

        <Menu infos={infos} />

        <div className="ml-auto mr-0 mb-0 py-3 lg:hidden">
          <button
            onClick={handleClick}
            type="button"
            className="bg-white text-blue mb-0 h-10 w-10 rounded-lg text-center text-3xl"
          >
            <FiMenu className="mx-auto" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Top;
