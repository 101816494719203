import Image from "next/image";

import { FiFacebook, FiYoutube, FiInstagram } from "react-icons/fi";

export function Footer({ infos }) {
  return (
    <div className="bg-blue text-white py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 items-center mb-6">
          <div className="flex items-center">
            <div className="flex-none">
              <Image
                src="/logo.svg"
                width="120"
                height="60"
                alt="Pouso Alegre"
              />
            </div>
            <div className="ml-4 leading-5">
              {infos.phone} / {infos.email}
            </div>
          </div>
          <div>
            <Image
              src="/logos-footer.jpg"
              width="1520"
              height="175"
              alt=""
              className="rounded-xl rounded-bl-none"
            />
          </div>
        </div>

        <div className="block lg:flex items-center">
          <div className="flex-1 mb-4 lg:mb-0">
            <span>
              © 2024 - Secretaria de Comunicação Social, Lazer e Turismo de
              Pouso Alegre. Desenvolvido por Visualize Comunicação.
            </span>
          </div>
          <div className="flex items-center -mx-1">
            {infos.facebook && (
              <a
                href={infos.facebook}
                rel="noopener"
                target="_blank"
                className="block bg-black bg-opacity-30 hover:bg-opacity-40 w-10 h-10 flex items-center justify-center mx-1 rounded-t-md rounded-br-md"
              >
                <FiFacebook />
              </a>
            )}
            {infos.instagram && (
              <a
                href={infos.instagram}
                rel="noopener"
                target="_blank"
                className="block bg-black bg-opacity-30 hover:bg-opacity-40 w-10 h-10 flex items-center justify-center mx-1 rounded-t-md rounded-br-md"
              >
                <FiInstagram />
              </a>
            )}
            {infos.youtube && (
              <a
                href={infos.youtube}
                rel="noopener"
                target="_blank"
                className="block bg-black bg-opacity-30 hover:bg-opacity-40 w-10 h-10 flex items-center justify-center mx-1 rounded-t-md rounded-br-md"
              >
                <FiYoutube />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
