import Link from "next/link";
import { useRouter } from "next/router";
import { FiX } from "react-icons/fi";

import styled from "./Menu.module.scss";

import { useEffect } from "react";

export const MENU = [
  { title: "Início", link: "/" },
  { title: "Conheça a Marca", link: "/conheca" },
  { title: "História", link: "/historia" },
  { title: "Atrativos", link: "/atrativos" },
  { title: "Guias Turísticos", link: "/guias" },
  { title: "Calendário Oficial", link: "/calendario" },
  { title: "Agenda", link: "/agenda" },
  { title: "Mapa", link: "/mapa" },
  { title: "Serviços", link: "/servicos" },
];

export function Menu({ menu, setMenu }) {
  const router = useRouter();

  const handleClick = () => {
    setMenu(false);
  };

  useEffect(() => {
    handleClick();
  }, [router]);

  return (
    <>
      <div
        onClick={() => handleClick()}
        className={`${styled.menuOverlay} ${menu ? "block" : "hidden"}`}
      />
      <div className={`${styled.menuContent} ${menu && styled.menuOpen} p-8`}>
        <button
          type="button"
          onClick={handleClick}
          className="text-3xl text-current"
        >
          <FiX />
        </button>
        {MENU.map((item, key) => (
          <div key={key} className="py-2">
            <Link href={item.link}>
              <span className="text-current hover:text-blue text-3xl font-bold font-serif">
                {item.title}
              </span>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
}

export default Menu;
