import Link from "next/link";
import { useState } from "react";
import { FiFacebook, FiYoutube, FiInstagram } from "react-icons/fi";

export const MENU = [
  { title: "Início", link: "/" },
  { title: "Conheça a Marca", link: "/conheca" },
  { title: "História", link: "/historia" },
  {
    title: "Atrativos",
    link: "/atrativos",
    submenus: [
      { title: "Conheça os atrativos", link: "/atrativos" },
      { title: "Guias Turísticos", link: "/guias" },
    ],
  },
  { title: "Calendário Oficial", link: "/calendario" },
  { title: "Agenda", link: "/agenda" },
  { title: "Mapa", link: "/mapa" },
  { title: "Serviços", link: "/servicos" },
];

function Item(props) {
  const [isShown, setIsShown] = useState(false);

  return (
    <div
      className="px-2 xl:px-3 font-semibold relative"
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <Link href={props.link}>
        <span className="text-current hover:text-blue">{props.title}</span>
      </Link>
      {props.submenus && isShown && (
        <div className="absolute pt-2 -ml-16">
          <div className="bg-gray px-6 py-2 rounded-md">
            {props.submenus.map((subitem, key) => (
              <div key={key}>
                <Link href={subitem.link}>
                  <span className="text-current hover:text-blue block whitespace-nowrap my-1">
                    {subitem.title}
                  </span>
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export function Menu({ infos }) {
  return (
    <div className="flex-grow hidden lg:block">
      <div className="flex justify-end items-center py-7">
        <div className="flex px-3">
          {MENU.map((item, key) => (
            <Item key={key} {...item} />
          ))}
        </div>
        <div className="flex -mx-1">
          {infos.facebook && (
            <a
              href={infos.facebook}
              rel="noopener"
              style={{ backgroundColor: "#1877F2" }}
              target="_blank"
              className="mx-1 block w-8 h-8 rounded-t-full rounded-br-full text-white"
            >
              <span className="flex justify-center py-2">
                <FiFacebook />
              </span>
            </a>
          )}
          {infos.instagram && (
            <a
              href={infos.instagram}
              rel="noopener"
              style={{ backgroundColor: "#8134AF" }}
              target="_blank"
              className="mx-1 block w-8 h-8 rounded-t-full rounded-br-full text-white"
            >
              <span className="flex justify-center py-2">
                <FiInstagram />
              </span>
            </a>
          )}
          {infos.youtube && (
            <a
              href={infos.youtube}
              rel="noopener"
              style={{ backgroundColor: "#EC2117" }}
              target="_blank"
              className="mx-1 block w-8 h-8 rounded-t-full rounded-br-full text-white"
            >
              <span className="flex justify-center py-2">
                <FiYoutube />
              </span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default Menu;
