import Link from 'next/link';
import { useMemo } from 'react';

const Arrow = (props) => {
  return (
    <svg
      className={`${props.className} h-5 w-auto text-gray-darken mx-1`}
      fill='currentColor'
      viewBox='0 0 20 20'
    >
      <path
        fillRule='evenodd'
        d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
};

export function PageContainer(props) {
  const returnUrl = useMemo(() => {
    if (props.nav.length > 1) {
      return props.nav[props.nav.length - 2].link;
    } else {
      return '/';
    }
  }, []);

  return (
    <>
      <div className='bg-gray-lighten py-3'>
        <div className='container mx-auto px-4'>
          <ul className='hidden lg:flex justify-end text-gray-500 text-current text-sm'>
            <li className='inline-flex items-center'>
              <Link href='/'>Início</Link>
            </li>
            {props.nav.map((item, key) => (
              <li key={key} className='inline-flex items-center'>
                <Arrow />
                <Link href={item.link}>{item.name}</Link>
              </li>
            ))}
          </ul>
          <ul className='flex lg:hidden justify-end text-gray-500 text-current text-sm'>
            <li className='inline-flex items-center'>
              <Arrow className='transform rotate-180' />
              <Link href={returnUrl}>Voltar</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className='bg-white'>{props.children}</div>
    </>
  );
}

export default PageContainer;
