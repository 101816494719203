import Image from "next/image";

export function ImageCustom({ url, name }) {
  return (
    <div className="bg-gray-light relative aspect-w-1 aspect-h-1 overflow-hidden rounded-xl rounded-bl-none">
      <div className="w-full h-full transition-all duration-300 transform hover:scale-110">
        <Image
          key={url}
          src={url}
          alt={name}
          width={500}
          height={500}
          style={{ height: "100%", objectFit: "cover" }}
        />
      </div>
    </div>
  );
}

export default ImageCustom;
