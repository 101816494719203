import { ApolloProvider } from "@apollo/client";
import { gql } from "@apollo/client";
import client from "../lib/apollo-client";
import "tailwindcss/tailwind.css";
import "../assets/flickity.css";
import "../assets/fonts.css";
import "../assets/main.scss";

import { Top, Footer, Menu } from "../components/layout";
import { useState } from "react";

function MyApp({ Component, pageProps, infos }) {
  const [menu, setMenu] = useState(false);

  return (
    <ApolloProvider client={client}>
      <Menu menu={menu} setMenu={setMenu} />
      <div className="flex flex-col min-h-screen">
        <Top infos={infos} menu={menu} setMenu={setMenu} />
        <div className="grow">
          <Component {...pageProps} />
        </div>
        <Footer infos={infos} />
      </div>
    </ApolloProvider>
  );
}

MyApp.getInitialProps = async (appContext) => {
  const infos = {
    email: "turismo@pousoalegre.mg.gov.br",
    phone: "(35) 3449-4150",
    facebook: "https://www.facebook.com/vemprapa",
    instagram: "https://www.instagram.com/vemprapa/",
    youtube: "https://www.youtube.com/channel/UCYePt5mDEikx2RlA9IHjK7A",
  };

  return {
    infos: infos,
  };
};

export default MyApp;
